import React from 'react';
import GlobalContext from '../app/GlobalContext';
import {Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Api from "../app/Api";

import { withSnackbar } from 'notistack';

class Referencement extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            metaTitle: '',
            metaDescription: '',
            h1: '',
        }
    }

    componentDidMount() {
        Api.token = this.context.token;

        Api.getPage(this.props.page)
            .then((data) => {
                this.setState({
                    metaTitle: data.data.metaTitle,
                    metaDescription: data.data.metaDescription,
                    h1: data.data.h1,
                });
            })
            .catch(() => {
                console.error('Erreur lors de la récupération des paramètres');
            })
    }

    handleChange = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const dataToPost = {
            metaTitle: data.get('metaTitle'),
            metaDescription: data.get('metaDescription'),
            h1: data.get('h1'),
        }

        Api.savePage(dataToPost, this.props.page)
            .then((result) => {
                this.props.enqueueSnackbar('Sauvegarde effectuée avec succès', {variant: 'success'})
            })
            .catch((error) => {
                this.props.enqueueSnackbar('Une erreur est survenue lors de la sauvegarde', {variant: 'error'})
            })
    }

    render() {
        return (
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>Référencement</Typography>
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="h1"
                                label="H1"
                                name="h1"
                                value={this.state.h1}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="metaTitle"
                                label="Meta title"
                                name="metaTitle"
                                value={this.state.metaTitle}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="metaDescription"
                                label="Meta  description"
                                name="metaDescription"
                                value={this.state.metaDescription}
                                onChange={this.handleChange}
                                multiline
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Enregistrer
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
        );
    }
}

export default withSnackbar(Referencement);
