import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import Api from '../app/Api.js';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactSortable } from "react-sortablejs";
import InfoIcon from '@mui/icons-material/Info';
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import UploaderPopupMedia from "./UploaderPopupMedia";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

class Uploader extends Component {
    constructor(props) {
        super(props);

        let largeurAttendue = (props.largeurAttendue) ? props.largeurAttendue : 800;
        let hauteurAttendue = (props.hauteurAttendue) ? props.hauteurAttendue : 1131;

        this.popupMedia = null;

        this.state = {
            tag: props.tag,
            files: [],
            filesUploading: [],
            dialogDelete: {
                isOpened: false,
                params: null
            },
            largeurAttendue: largeurAttendue,
            hauteurAttendue: hauteurAttendue
        };
        
        Api.token = props.token;
    }
    
    componentDidMount() {
        this.reloadMedias();
    }
    
    reloadMedias() {
        Api.getMedias(this.props.model, this.props.idModel, this.props?.dataModel)
            .then((result) => {
                if(result.status) {
                    let newArrayMedias = result.medias;

                    for(let file of this.state.files)
                    {
                        if(file.uploading)
                            newArrayMedias.push(file);
                    }

                    this.setState({files: newArrayMedias});
                }
                else
                    this.setState({files: []});
            })
            .catch((error) => {
                    console.error(error);
            })
    }

    onDrop(files)
    {
        for(let file of files)
        {
            let files = this.state.files;
            files.push({
                fileName: file.name,
                uploading: true
            });
            this.setState({files});

            Api.uploadMedia(file, this.props.model, this.props.idModel, this.props?.dataModel)
                .then((result) => {
                    this.removeFileFromFileUploading(file.name);

                    this.reloadMedias();
                })
                .catch((error) => {
                    this.removeFileFromFileUploading(file.name);

                    this.reloadMedias();

                    this.props.enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
                })
        }
    }

    removeFileFromFileUploading = (fileName) => {
        let files = this.state.files;
        let indexToDelete = -1;

        for(let index in files)
        {
            if(files[index].fileName == fileName)
            {
                indexToDelete = index;
                break;
            }
        }

        if (indexToDelete !== -1) {
            files.splice(indexToDelete, 1);
        }

        this.setState({files});
    }

    onReorder = () => {
        let files = [];

        for(let infoFile of this.state.files)
        {
            files.push(infoFile)
        }

        Api.reorderMedias(files, this.props.model, this.props.idModel, this.props?.dataModel)
            .then((result) => {
                this.reloadMedias();
            })
            .catch((error) => {
                this.reloadMedias();
            })
    }

    renderListFiles = () =>
    {
        let retour = null;

        if(this.state.files.length > 0)
        {
            retour = (
                <ReactSortable list={this.state.files} setList={(files) => this.setState({files})} onEnd={this.onReorder} className="uploader-images">
                    {
                        this.state.files.map((media, index) => {
                            if(media.uploading)
                            {
                                return <div className="uploader-image" key={index}>
                                    <CircularProgress />
                                </div>
                            }
                            // Vidéo
                            else if(media.iframe)
                            {
                                return <div className="uploader-image" key={index}>
                                    <div style={{width: '100px', height: '125px', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                        <PlayCircleOutlineIcon fontSize={'large'}/>
                                    </div>
                                    <div style={{justifyContent: 'center', display: 'flex', alignItems: 'flex-end'}}>
                                        <div className="uploader-supprimer" onClick={() => this.openDialogEdit(media)}><FaEdit /></div>
                                        <div className="uploader-supprimer" onClick={() => this.openDialogDeleteFromId(media.id)}><FaTrashAlt /></div>
                                    </div>
                                </div>
                            }
                            else
                               return <div className="uploader-image" key={index}>
                                <img src={media.file} />
                                    <div className="uploader-supprimer" onClick={() => this.openDialogDeleteFromFile(media.file)}><FaTrashAlt /></div>
                                </div>
                        })
                    }
                </ReactSortable>
            );
        }

        return retour;
    }

    openDialogEdit = (mediaData) => {
        this.popupMedia.edit(mediaData)
    }

    openDialogDeleteFromId = (id) =>
    {
        let dialogDelete = {
            isOpened: true,
            params: {id}
        };

        this.setState({
            dialogDelete: dialogDelete,
        })
    }

    openDialogDeleteFromFile = (file) =>
    {
        let dialogDelete = {
            isOpened: true,
            params: {file}
        };

        this.setState({
            dialogDelete: dialogDelete,
        })
    }

    closeDialogDelete = (response) =>
    {
        if(response)
        {
            Api.deleteMedia(this.state.dialogDelete.params, this.props.model, this.props.idModel, this.props?.dataModel)
                .then((result) => {
                    this.reloadMedias();

                    this.setState({
                        dialogDelete: {
                            isOpened: false,
                            params: null
                        },
                    })
                })
                .catch((error) => {
                    this.reloadMedias();

                    this.setState({
                        dialogDelete: {
                            isOpened: false,
                            params: null
                        },
                    })
                })
        }
        else
            this.setState({
                dialogDelete: {
                    isOpened: false,
                    params: null
                },
            })
    }

    render() {
        return (
            <Grid>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>{this.props.titre}</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => this.popupMedia.edit()}>Ajouter une vidéo</Button>
                    </Grid>
                </Grid>
                <Grid>
                    <div>
                        <Dropzone onDrop={(files) => this.onDrop(files)}>
                            {({getRootProps, getInputProps}) => (
                                <section className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <p>Glissez les images ici, ou cliquez pour sélectionner des fichiers</p>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize: 14}}><InfoIcon fontSize={'small'}/> <span style={{marginLeft: 5}}>Dimensions attendues : {this.state.largeurAttendue} * {this.state.hauteurAttendue}</span></div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        {this.renderListFiles()}

                        <Dialog
                            open={this.state.dialogDelete.isOpened}
                            onClose={() => this.closeDialogDelete(false)}
                        >
                            <DialogContent>
                                <DialogContentText>
                                    Etes-vous sur de vouloir supprimer cette image ?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.closeDialogDelete(true)} autoFocus>Oui</Button>
                                <Button onClick={() => this.closeDialogDelete(false)}>
                                    Non
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <UploaderPopupMedia
                            ref={(ref) => this.popupMedia = ref}
                            model={this.props.model}
                            idModel={this.props.idModel}
                            dataModel={this.props?.dataModel}
                            onRefresh={() => this.reloadMedias()}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default Uploader;