import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

const cookies = new Cookies();

const GlobalContext = React.createContext()

class GlobalProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: cookies.get('token'),
            dialogOuiNon: {
                isOpened: false,
                texte: '',
                onResponse: (response) => {}
            }
        };
    }

    dialogOuiNon = (texte, callback) => {
        this.setState({
              dialogOuiNon: {
                  isOpened: true,
                  texte: texte,
                  onResponse: callback
              }
          });
    }

    closeDialogOuiNon = (response) => {
        this.state.dialogOuiNon.onResponse(response);

        this.setState({
            dialogOuiNon: {
                isOpened: false,
                texte: this.state.dialogOuiNon.texte,
                onResponse: (response) => {}
            }
        });
    }

    setToken = (token) => {
        cookies.set('token', token, {path: '/'});
        this.setState((prevState) => ({token}))
    }

    render() {
        const {children} = this.props
        const {token} = this.state
        const {setToken, dialogOuiNon} = this

        return (
            <GlobalContext.Provider
                value={{
                    token,
                    setToken,
                    dialogOuiNon,
                }}
            >
                {children}
                <Dialog
                    open={this.state.dialogOuiNon.isOpened}
                    onClose={() => this.closeDialogOuiNon(false)}
                >
                    <DialogContent>
                        <DialogContentText>
                            {this.state.dialogOuiNon.texte}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.closeDialogOuiNon(true)} autoFocus>Oui</Button>
                        <Button onClick={() => this.closeDialogOuiNon(false)}>
                            Non
                        </Button>
                    </DialogActions>
                </Dialog>
            </GlobalContext.Provider>
        )
    }
}

export default GlobalContext

export { GlobalProvider }