import React from 'react';
import GlobalContext from '../app/GlobalContext';
import {Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Api from "../app/Api";

import { withSnackbar } from 'notistack';
import {useParams} from "react-router-dom";

const modelCRUD = 'contact';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class TextileFiche extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            id: props.params.id,
            formData: {
                date: '',
                objet: '',
                nom: '',
                prenom: '',
                raisonSociale: '',
                email: '',
                telephone: '',
                demande: ''
            }
        }

        this.champs = [
            {
                index: 'date',
                libelle: 'Date'
            },
            {
                index: 'objet',
                libelle: 'Objet'
            },
            {
                index: 'nom',
                libelle: 'Nom'
            },
            {
                index: 'prenom',
                libelle: 'Prénom'
            },
            {
                index: 'raisonSociale',
                libelle: 'Raison sociale'
            },
            {
                index: 'email',
                libelle: 'Email'
            },
            {
                index: 'telephone',
                libelle: 'Téléphone'
            },
            {
                index: 'demande',
                libelle: 'Demande'
            },
        ]
    }

    componentDidMount() {
        Api.token = this.context.token;

        Api.get(modelCRUD, this.state.id)
            .then((data) => {
                this.setState({
                    formData: data.data
                })
            })
            .catch(() => {
                console.error('Erreur lors de la récupération des données');
            })
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>Informations</Typography>
                        {
                            this.champs.map((info) => {
                                let valeur = this.state.formData[info.index];

                                if(info.index == 'date') {
                                    let date = new Date(valeur);
                                    valeur = date.toLocaleDateString("fr") + ' ' + date.toLocaleTimeString("fr")
                                }

                                return <Box sx={{ mt: 1 }}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            {info.libelle} :
                                        </Grid>
                                        <Grid item xs={9} class="pre-line">
                                            {valeur}
                                        </Grid>
                                    </Grid>
                                </Box>
                            })
                        }
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withSnackbar(withParams(TextileFiche));
