import React from 'react';
import GlobalContext from '../app/GlobalContext';
import Uploader from '../components/Uploader';
import {Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Api from "../app/Api";

import { withSnackbar } from 'notistack';
import {useParams} from "react-router-dom";

const modelCRUD = 'illustration';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class IllustrationFiche extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            id: props.params.id,
            formData: {
                titre: '',
                technique: '',
                prix: '',
                texteComplementaire: ''
            }
        }
    }

    componentDidMount() {
        Api.token = this.context.token;

        Api.get(modelCRUD, this.state.id)
            .then((data) => {
                this.setState({
                    formData: data.data
                })
            })
            .catch(() => {
                console.error('Erreur lors de la récupération des données');
            })
    }

    handleChange = (event) => {
        let formData = this.state.formData;

        formData[event.target.id] = event.target.value;

        this.setState({formData});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        new FormData(event.currentTarget);

        let formData = this.state.formData;

        if(formData.medias)
            delete formData.medias;

        Api.update(modelCRUD, this.state.id, formData)
            .then((result) => {
                this.props.enqueueSnackbar('Sauvegarde effectuée avec succès', {variant: 'success'})
            })
            .catch((error) => {
                this.props.enqueueSnackbar('Une erreur est survenue lors de la sauvegarde', {variant: 'error'})
            })
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid>
                            <Uploader titre={'Images'} token={this.context.token} model={"illustrations"} idModel={this.state.id}/>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>Informations</Typography>
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="titre"
                                label="Titre"
                                name="titre"
                                value={this.state.formData.titre}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="technique"
                                label="Technique"
                                name="technique"
                                value={this.state.formData.technique}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="texteComplementaire"
                                label="Texte complementaire"
                                name="texteComplementaire"
                                value={this.state.formData.texteComplementaire}
                                onChange={this.handleChange}
                                multiline
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="prix"
                                label="Prix"
                                name="prix"
                                value={this.state.formData.prix}
                                onChange={this.handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Enregistrer
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withSnackbar(withParams(IllustrationFiche));
