import React from 'react';
import GlobalContext from '../app/GlobalContext';
import {Grid, Paper} from "@mui/material";
import Api from "../app/Api";

import { withSnackbar } from 'notistack';
import TableCRUD from "../components/TableCRUD";
import Referencement from "../components/Referencement";

const headCells = [
    {
        id: 'titre',
        numeric: false,
        disablePadding: true,
        label: 'Titre',
        isSortable: false
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
        isSortable: false
    },
];

class BandeDessinee extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        Api.token = this.context.token;
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TableCRUD tableTitre="Bande dessinées" headCells={headCells} table={"bd"} routeFiche={"/bd/"} add={true}/>
                    </Paper>
                </Grid>
                <Referencement page={'bd'}/>
            </Grid>
        );
    }
}

export default withSnackbar(BandeDessinee);
