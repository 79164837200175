import React from 'react';
import GlobalContext from '../app/GlobalContext';
import Uploader from '../components/Uploader';
import {Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Api from "../app/Api";

import { withSnackbar } from 'notistack';
import TableCRUD from "../components/TableCRUD";
import Referencement from "../components/Referencement";

const headCells = [
    {
        id: 'titre',
        numeric: false,
        disablePadding: true,
        label: 'Titre',
        isSortable: false
    },
    {
        id: 'technique',
        numeric: false,
        disablePadding: false,
        label: 'Technique',
        isSortable: false
    },
    {
        id: 'nbPassages',
        numeric: true,
        disablePadding: false,
        label: 'Nombre de passages',
        isSortable: false
    },
    {
        id: 'format',
        numeric: true,
        disablePadding: false,
        label: 'Format',
        isSortable: false
    },
    {
        id: 'prix',
        numeric: true,
        disablePadding: false,
        label: 'Prix',
        isSortable: false
    },
];

class Serigraphie extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            titreEdito: '',
            texteEdito: '',
            titreSerigraphie: ''
        }
    }

    componentDidMount() {
        Api.token = this.context.token;

        Api.getPage('serigraphie')
            .then((data) => {
                this.setState({
                    titreEdito: data.data.titreEdito,
                    texteEdito: data.data.texteEdito,
                    titreSerigraphie: data.data.titreSerigraphie
                });
            })
            .catch(() => {
                console.error('Erreur lors de la récupération des paramètres');
            })
    }

    handleChange = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const dataToPost = {
            titreEdito: data.get('titreEdito'),
            texteEdito: data.get('texteEdito'),
            titreSerigraphie: data.get('titreSerigraphie')
        }

        Api.savePage(dataToPost, 'serigraphie')
            .then((result) => {
                this.props.enqueueSnackbar('Sauvegarde effectuée avec succès', {variant: 'success'})
            })
            .catch((error) => {
                this.props.enqueueSnackbar('Une erreur est survenue lors de la sauvegarde', {variant: 'error'})
            })
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid>
                            <Uploader titre={'Images'} token={this.context.token} model={"pages"} idModel={"serigraphie"} largeurAttendue={1000} hauteurAttendue={800}/>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>Informations</Typography>
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="titreEdito"
                                label="Titre édito"
                                name="titreEdito"
                                value={this.state.titreEdito}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="texteEdito"
                                label="Texte édito"
                                name="texteEdito"
                                value={this.state.texteEdito}
                                onChange={this.handleChange}
                                multiline
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="titreSerigraphie"
                                label="Titre sérigraphies"
                                name="titreSerigraphie"
                                value={this.state.titreSerigraphie}
                                onChange={this.handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Enregistrer
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TableCRUD tableTitre="Sérigraphies" headCells={headCells} table={"serigraphie"} routeFiche={"/serigraphie/"} add={true}/>
                    </Paper>
                </Grid>
                <Referencement page={'serigraphie'}/>
            </Grid>
        );
    }
}

export default withSnackbar(Serigraphie);
