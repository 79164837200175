import React from 'react';
import GlobalContext from '../app/GlobalContext';
import Api from "../app/Api";

import { withSnackbar } from 'notistack';
import Table from "../components/Table";

import { useNavigate } from "react-router-dom";

function withNavigate(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}

class TableCRUD extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            dataListe: []
        }
    }
    
    componentDidMount() {
        Api.token = this.context.token;

        this.updateListe();
    }

    updateListe = () => {
        Api.get(this.props.table, null)
            .then((data) => {
                let dataListe = data.data;

                this.setState({
                    dataListe: dataListe
                })
            })
            .catch(() => {
                console.error('Erreur lors de la récupération des paramètres');
            })
    }

    onEdit = (row) => {
        this.props.navigate(this.props.routeFiche + row._id);
    }

    onAdd = () => {
        Api.create(this.props.table,{})
            .then((dataRetour) => {
                this.props.navigate(this.props.routeFiche + dataRetour.insertedId);
            })
            .catch(() => {
                this.props.enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            })
    }

    onDelete = (idsSelected) => {
        this.context.dialogOuiNon('Etes-vous sûr de vouloir supprimer les enregistrements sélectionnés ?', (response) => {
            if(response)
            {
                Api.delete(this.props.table,idsSelected)
                    .then((dataRetour) => {
                        this.updateListe();
                    })
                    .catch(() => {
                        this.props.enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
                    });
            }
        })
    }

    onReorder = (idsToReorder) => {
        Api.reorder(this.props.table, idsToReorder)
            .then((dataRetour) => {
                // Ne rien faire
            })
            .catch(() => {
                this.props.enqueueSnackbar('Une erreur est survenue', {variant: 'error'})
            });
    }

    render() {
        return (
            <Table
                tableTitre={this.props.tableTitre}
                headCells={this.props.headCells}
                data={this.state.dataListe}
                add={this.props.add}
                onAdd={this.onAdd}
                onDelete={this.onDelete}
                onEdit={this.onEdit}
                onReorder={this.onReorder}
            />
        );
    }
}

export default withSnackbar(withNavigate(TableCRUD));
