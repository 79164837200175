import React from 'react';
import GlobalContext from '../app/GlobalContext';
import {Grid, Paper} from "@mui/material";
import Api from "../app/Api";

import { withSnackbar } from 'notistack';
import TableCRUD from "../components/TableCRUD";
import Referencement from "../components/Referencement";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Home from "./Home";

const headCells = [
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        isSortable: true,
        formatDate: true
    },
    {
        id: 'nom',
        numeric: false,
        disablePadding: true,
        label: 'Nom',
        isSortable: true
    },
    {
        id: 'prenom',
        numeric: false,
        disablePadding: false,
        label: 'Prénom',
        isSortable: true
    },
];

class Contact extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            titre: '',
            texte: '',
            adresse: '',
            telephone: '',
            email: '',
            lienLinkedin: '',
            lienFacebook: '',
            lienInstagram: '',
            emailReceptionContact: '',
        }
    }

    componentDidMount() {
        Api.token = this.context.token;

        Api.getPage('contact')
            .then((data) => {
                this.setState({
                    titre: data.data.titre,
                    texte: data.data.texte,
                    adresse: data.data.adresse,
                    telephone: data.data.telephone,
                    email: data.data.email,
                    lienFacebook: data.data.lienFacebook,
                    lienLinkedin: data.data.lienLinkedin,
                    lienInstagram: data.data.lienInstagram,
                    emailReceptionContact: data.data.emailReceptionContact,
                });
            })
            .catch(() => {
                console.error('Erreur lors de la récupération des paramètres');
            })
    }

    handleChange = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const dataToPost = {
            titre: data.get('titre'),
            texte: data.get('texte'),
            adresse: data.get('adresse'),
            telephone: data.get('telephone'),
            email: data.get('email'),
            lienFacebook: data.get('lienFacebook'),
            lienLinkedin: data.get('lienLinkedin'),
            lienInstagram: data.get('lienInstagram'),
            emailReceptionContact: data.get('emailReceptionContact'),
        }

        Api.savePage(dataToPost, 'contact')
            .then((result) => {
                this.props.enqueueSnackbar('Sauvegarde effectuée avec succès', {variant: 'success'})
            })
            .catch((error) => {
                this.props.enqueueSnackbar('Une erreur est survenue lors de la sauvegarde', {variant: 'error'})
            })
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TableCRUD tableTitre="Demandes de contact" headCells={headCells} table={"contact"} routeFiche={"/contact/"} add={false}/>
                    </Paper>
                </Grid>
                <Referencement page={'contact'}/>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>Informations</Typography>
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="titre"
                                label="Titre"
                                name="titre"
                                value={this.state.titre}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="texte"
                                label="Texte"
                                name="texte"
                                value={this.state.texte}
                                onChange={this.handleChange}
                                multiline
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="adresse"
                                label="Adresse"
                                name="adresse"
                                value={this.state.adresse}
                                onChange={this.handleChange}
                                multiline
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="telephone"
                                label="Téléphone"
                                name="telephone"
                                value={this.state.telephone}
                                onChange={this.handleChange}
                            /><TextField
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Adresse email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="lienFacebook"
                                label="Lien Facebook"
                                name="lienFacebook"
                                value={this.state.lienFacebook}
                                onChange={this.handleChange}
                            /><TextField
                                margin="normal"
                                fullWidth
                                id="lienLinkedin"
                                label="Lien LinkedIn"
                                name="lienLinkedin"
                                value={this.state.lienLinkedin}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="lienInstagram"
                                label="Lien Instagram"
                                name="lienInstagram"
                                value={this.state.lienInstagram}
                                onChange={this.handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="emailReceptionContact"
                                label="Email réception des demandes de contact"
                                name="emailReceptionContact"
                                value={this.state.emailReceptionContact}
                                onChange={this.handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Enregistrer
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withSnackbar(Contact);
