import './App.css';

import React from 'react';

import GlobalContext from './app/GlobalContext';

import Auth from './ecrans/Auth';
import Home from './ecrans/Home';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

import { SnackbarProvider } from 'notistack';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Serigraphie from "./ecrans/Serigraphie";
import Accueil from "./ecrans/Accueil";
import BandeDessinee from "./ecrans/BandeDessinee";
import Illustration from "./ecrans/Illustration";
import Textile from "./ecrans/Textile";
import Contact from "./ecrans/Contact";
import SerigraphieFiche from "./ecrans/SerigraphieFiche";
import BandeDessineeFiche from "./ecrans/BandeDessineeFiche";
import IllustrationFiche from "./ecrans/IllustrationFiche";
import ContactFiche from "./ecrans/ContactFiche";
import TextileFiche from "./ecrans/ContactFiche";

import {routes, getRoutes} from "./app/Architecture";

const theme = createTheme({},
    frFR,
);

class App extends React.Component
{
    static contextType = GlobalContext;

    renderContent = () => {
        if(this.context.token)
        {
            return (
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />}>
                            {
                                getRoutes(routes).map((routeElement) => {
                                    return <Route key={routeElement.link} path={routeElement.link} element={routeElement.getComponent()} />
                                })
                            }
                        </Route>
                    </Routes>
                </BrowserRouter>
            );
        }
        else
            return (
                <Auth />
            );
    }

    handleClose = (alert) => {
       this.context.deleteAlert(alert);
    }

    render() {
       return <ThemeProvider theme={theme}>
               <SnackbarProvider>
                   {this.renderContent()}
           </SnackbarProvider>
       </ThemeProvider>
    }
}

export default App;
