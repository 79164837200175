import Accueil from '../ecrans/Accueil';
import Serigraphie from "../ecrans/Serigraphie";
import SerigraphieFiche from "../ecrans/SerigraphieFiche";
import Illustration from "../ecrans/Illustration";
import IllustrationFiche from "../ecrans/IllustrationFiche";
import BandeDessinee from "../ecrans/BandeDessinee";
import BandeDessineeFiche from "../ecrans/BandeDessineeFiche";
import Textile from "../ecrans/Textile";
import TextileFiche from "../ecrans/TextileFiche";
import Contact from "../ecrans/Contact";
import ContactFiche from "../ecrans/ContactFiche";

import { matchPath } from "react-router-dom";
import BlocAccueil from "../ecrans/BlocAccueil";

const routes = [
    {
        id: 'accueil',
        titre: 'Accueil',
        link: '/',
        getComponent: () => <Accueil />,
        isNavbar: true,
        childrens: [
            {
                id: 'bloc-accueil',
                titre: 'Bloc accueil - édition',
                link: '/bloc-accueil/:id',
                getComponent: () => <BlocAccueil />,
                isNavbar: false
            },
            {
                id: 'serigraphie',
                titre: 'Sérigraphie',
                link: '/serigraphie',
                getComponent: () => <Serigraphie />,
                isNavbar: true,
                childrens: [
                    {
                        id: 'serigraphie-create',
                        titre: 'Sérigraphie - édition',
                        link: '/serigraphie/:id',
                        getComponent: () => <SerigraphieFiche />,
                        isNavbar: false
                    },
                ]
            },
            {
                id: 'bd',
                titre: 'BD',
                link: '/bd',
                getComponent: () => <BandeDessinee />,
                isNavbar: true,
                childrens: [
                    {
                        id: 'bd-create',
                        titre: 'BD - édition',
                        link: '/bd/:id',
                        getComponent: () => <BandeDessineeFiche />,
                        isNavbar: false
                    },
                ]
            },
            {
                id: 'illustration',
                titre: 'Illustration',
                link: '/illustration',
                getComponent: () => <Illustration />,
                isNavbar: true,
                childrens: [
                    {
                        id: 'illustration-create',
                        titre: 'Illustration - édition',
                        link: '/illustration/:id',
                        getComponent: () => <IllustrationFiche />,
                        isNavbar: false
                    },
                ]
            },
            {
                id: 'textile',
                titre: 'Textile',
                link: '/textile',
                getComponent: () => <Textile />,
                isNavbar: true,
                childrens: [
                    {
                        id: 'textile-create',
                        titre: 'Textile - édition',
                        link: '/textile/:id',
                        getComponent: () => <TextileFiche />,
                        isNavbar: false
                    },
                ]
            },
            {
                id: 'contact',
                titre: 'Contact',
                link: '/contact',
                getComponent: () => <Contact />,
                isNavbar: true,
                childrens: [
                    {
                        id: 'contact-create',
                        titre: 'Contact - édition',
                        link: '/contact/:id',
                        getComponent: () => <ContactFiche />,
                        isNavbar: false
                    },
                ]
            },
        ]
    },
];

const getHierarchieActive = (arrayRoutes, currentPath) => {
    let retour = []

    for(let info of arrayRoutes)
    {
        const match = matchPath(
            { path: info.link },
            currentPath
        );

        if(match)
        {
            retour.push(info);
            break;
        }
        else if(info.childrens && info.childrens.length > 0)
        {
            let retourSearchChildren = getHierarchieActive(info.childrens, currentPath);

            if(retourSearchChildren.length > 0)
            {
                retour = [info].concat(retourSearchChildren);
                break;
            }
        }
    }

    return retour;
}

const exportNavbar = () => {
    let retour = [];

    for(let routeNiveau0 of routes)
    {
        if(routeNiveau0.isNavbar)
        {
            retour.push(routeNiveau0);

            for(let routeNiveau1 of routeNiveau0.childrens)
            {
                if (routeNiveau1.isNavbar)
                    retour.push(routeNiveau1);
            }
        }

    }

    return retour;
}

const getRoutes = (routes) => {
    let retour = [];

    for(let route of routes)
    {
        retour.push({
            link: route.link,
            getComponent: route.getComponent
        });

        if(route.childrens)
            retour.push(...getRoutes(route.childrens))
    }

    return retour;
}

export {routes, exportNavbar, getHierarchieActive, getRoutes};