import React from 'react';
import GlobalContext from '../app/GlobalContext';
import Uploader from '../components/Uploader';
import {Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Api from "../app/Api";

import { withSnackbar } from 'notistack';
import Referencement from "../components/Referencement";
import Home from "./Home";
import TableCRUD from "../components/TableCRUD";

const headCells = [
    {
        id: 'titre',
        numeric: false,
        disablePadding: true,
        label: 'Titre',
        isSortable: false
    },
];

class Accueil extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            texteEdito: ''
        }
    }

    componentDidMount() {
        Api.token = this.context.token;

        Api.getPage('accueil')
            .then((data) => {
                this.setState({
                    texteEdito: data.data.texteEdito
                });
            })
            .catch(() => {
                console.error('Erreur lors de la récupération des paramètres');
            })
    }

    handleChange = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const dataToPost = {
            texteEdito: data.get('texteEdito')
        }

        Api.savePage(dataToPost, 'accueil')
            .then((result) => {
                this.props.enqueueSnackbar('Sauvegarde effectuée avec succès', {variant: 'success'})
            })
            .catch((error) => {
                this.props.enqueueSnackbar('Une erreur est survenue lors de la sauvegarde', {variant: 'error'})
            })
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TableCRUD tableTitre="Blocs" headCells={headCells} table={"pagesBlocs/accueil"} routeFiche={"/bloc-accueil/"} add={true}/>
                    </Paper>
                </Grid>

                <Referencement page={'accueil'}/>
            </Grid>
        );
    }
}

export default withSnackbar(Accueil);
