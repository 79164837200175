import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import GlobalContext from '../app/GlobalContext';

import {Breadcrumbs, Grid} from "@mui/material";
import {routes, exportNavbar, getHierarchieActive} from "../app/Architecture"

import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";

function withLocation(Component) {
    return props => <Component {...props} location={useLocation()} />;
}

function withNavigate(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}

class Home extends React.Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props);

        this.state = {
            anchorElNav: null
        }
    }

    redirect(link)
    {
        this.handleCloseNavMenu();
        this.props.navigate(link);
    }

    deconnexion = () =>
    {
        this.handleCloseNavMenu();
        this.context.setToken(null);
    }

    handleOpenNavMenu = (event) => {
        this.setState({anchorElNav: event.currentTarget});
    };

    handleCloseNavMenu = () => {
        this.setState({anchorElNav: null});
    };

    render() {
        let hierarchieActive = getHierarchieActive(routes, this.props.location.pathname);

        let navbar = exportNavbar();

        let logo = <img src="/logo.png" style={{height: 50, backgroundColor: "white", borderRadius: 4, padding: 3}}/>

        return (
            <Box
                component="main"
                sx={{
                    backgroundColor: '#f5f5f5',
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <AppBar position="static">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                            >
                                {logo}
                            </Typography>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={this.handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(this.state.anchorElNav)}
                                    onClose={this.handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {navbar.map((page) => (
                                        <MenuItem key={page.id} onClick={() => this.redirect(page.link)}>
                                            <Typography textAlign="center">{page.titre}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                            >
                                {logo}
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {navbar.map((page) => (
                                    <Button
                                        key={page.id}
                                        onClick={() => this.redirect(page.link)}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page.titre}
                                    </Button>
                                ))}
                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                <Button
                                    key={'deconnexion'}
                                    onClick={this.deconnexion}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    Déconnexion
                                </Button>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>

                <Container maxWidth="lg" sx={{ mt: 2 }}>
                    <Grid container>
                        <Breadcrumbs aria-label="breadcrumb">
                            {
                                hierarchieActive.map((infosMenu, index) => {
                                    if(hierarchieActive.length == index + 1)
                                        return <Typography color="text.primary" key={index}>{infosMenu.titre}</Typography>
                                    else
                                       return  <Link to={infosMenu.link} key={index} style={{color: 'black'}}>
                                           {infosMenu.titre}
                                       </Link>
                                })
                            }
                        </Breadcrumbs>
                    </Grid>
                </Container>
                <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
                    <Outlet />
                </Container>
            </Box>
        );
    }
}

export default withLocation(withNavigate(Home));
