class Api {
    constructor(token) {
        this.urlApiBase = process.env.REACT_APP_API_URL;
        
        this.token = null;
    }

    auth(email, password) 
    {
        return fetch(this.urlApiBase + 'auth/', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email, password})
        })
            .then(function(res){ return res.json(); })
        ;
    }
    
    getMedias(model, idModel, dataModel)
    {
        return fetch(this.urlApiBase + 'medias/get', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Token": this.token
            },
            body: JSON.stringify({model, idModel, dataModel})
        })
            .then(function(res){ return res.json(); })
        ;
    }
    
    uploadMedia(media, model, idModel, dataModel)
    {
        let formData = new FormData();

        formData.append("media", media);
        formData.append("model", model);
        formData.append("idModel", idModel);
        formData.append("dataModel", JSON.stringify(dataModel));

        const ctrl = new AbortController();
        setTimeout(() => ctrl.abort(), 10000);
    
        return fetch(this.urlApiBase + 'medias/uploadImg', {
            method: "POST", 
            body: formData,
            headers: {
                "Token": this.token
            },
            signal: ctrl.signal
        })
            .then(function(res){ return res.json(); })
        ;
    }

    deleteMedia(data, model, idModel, dataModel)
    {
        let dataToPost = {
            model, idModel, dataModel
        }

        if(data.id)
            dataToPost.id = data.id;
        else if(data.file)
            dataToPost.file = data.file

        return fetch(this.urlApiBase + 'medias/delete', {
            method: 'POST',
            body: JSON.stringify(dataToPost),
            headers: {
                "Content-Type": "application/json",
                "Token": this.token
            },
        })
            .then(function(res){ return res.json(); })
            ;
    }

    editMedia(id, data, model, idModel, dataModel)
    {
        return fetch(this.urlApiBase + 'medias/edit', {
            method: 'POST',
            body: JSON.stringify({
                id, data, model, idModel, dataModel
            }),
            headers: {
                "Content-Type": "application/json",
                "Token": this.token
            },
        })
            .then(function(res){ return res.json(); })
            ;
    }

    insertIframe(iframe, model, idModel, dataModel)
    {
        return fetch(this.urlApiBase + 'medias/uploadIframe', {
            method: 'POST',
            body: JSON.stringify({
                model,
                idModel,
                dataModel,
                iframe
            }),
            headers: {
                "Content-Type": "application/json",
                "Token": this.token
            },
        })
            .then(function(res){ return res.json(); })
            ;
    }

    reorderMedias(medias, model, idModel, dataModel)
    {
        return fetch(this.urlApiBase + 'medias/reorder', {
            method: 'POST',
            body: JSON.stringify({
                model,
                idModel,
                dataModel,
                medias
            }),
            headers: {
                "Content-Type": "application/json",
                "Token": this.token
            },
        })
            .then(function(res){ return res.json(); })
            ;
    }

    saveParams(data) {
        return fetch(this.urlApiBase + 'params/', {
            method: 'POST',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(function(res){ return res.json(); })
            ;
    }

    getParams() {
        return fetch(this.urlApiBase + 'params/', {
            method: 'GET',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(function(res){ return res.json(); })
            ;
    }

    savePage(data, id) {
        return fetch(this.urlApiBase + 'pages/', {
            method: 'POST',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({infos: data, id: id})
        })
            .then(function(res){ return res.json(); })
            ;
    }

    getPage(id) {
        return fetch(this.urlApiBase + 'pages/' + id + '/', {
            method: 'GET',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(function(res){ return res.json(); })
            ;
    }

    get(model, id)
    {
        let url = this.urlApiBase + model + '/get';

        if(id)
            url += '/' + id

        return fetch(url, {
            method: 'GET',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            }
        })
            .then(function(res){ return res.json(); })
            ;
    }

    update(model, id, data)
    {
        return fetch(this.urlApiBase + model + '/update/' + id, {
            method: 'POST',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(function(res){ return res.json(); })
            ;
    }

    create(model, data)
    {
        return fetch(this.urlApiBase + model + '/create', {
            method: 'POST',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(function(res){ return res.json(); })
            ;
    }

    delete(model, id)
    {
        return fetch(this.urlApiBase + model + '/delete', {
            method: 'POST',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ids: id})
        })
            .then(function(res){ return res.json(); })
            ;
    }

    reorder(model, ids)
    {
        return fetch(this.urlApiBase + model + '/reorder', {
            method: 'POST',
            headers: {
                "Token": this.token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ids: ids})
        })
            .then(function(res){ return res.json(); })
            ;
    }
}

export default new Api();