import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Api from '../app/Api.js';
import GlobalContext from '../app/GlobalContext';

import { withSnackbar } from 'notistack';

class Auth extends React.Component {
    static contextType = GlobalContext
    
    constructor(props) {
        super(props);
    }
    
    handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
                
        Api.auth(data.get('email'), data.get('password'))
            .then((result) => {
                if(result.status)
                    this.context.setToken(result.token);
                else
                {
                    this.props.enqueueSnackbar('Une erreur est survenue lors de la connexion', {variant: 'error'});
                    this.context.setToken(null);
                }
            })
            .catch((error) => {
                console.error(error);
                this.props.enqueueSnackbar('Une erreur est survenue lors de la connexion', {variant: 'error'})
            })
    }

    render() {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Connexion
                    </Typography>
                    <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            defaultValue=""
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            defaultValue=""
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Connexion
                        </Button>
                    </Box>
                </Box>
            </Container>
        );
    }
}

export default withSnackbar(Auth);
