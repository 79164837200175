import React, {Component} from 'react';
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {TextField} from "@mui/material";
import Api from "../app/Api";


class UploaderPopupMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
            iframeVideo: '',
            data: null
        }
    }

    edit(data) {
        this.setState({
            isOpened: true,
            data: data,
            iframeVideo: data?.iframe
        });
    }

    closeDialog(response) {
        if(response)
        {
            if(this.state.data && this.state.data.id) {
                Api.editMedia(this.state.data.id, {iframe: this.state.iframeVideo}, this.props.model, this.props.idModel, this.props?.dataModel)
                    .then((res) => {
                            this.setState({
                                isOpened: false
                            });
                        })
            }
            else{
                Api.insertIframe(this.state.iframeVideo, this.props.model, this.props.idModel, this.props?.dataModel)
                    .then((res) => {
                        this.setState({
                            isOpened: false
                        });
                    })
            }

            this.props.onRefresh();
        }
        else {
            this.setState({
                isOpened: false
            });
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    render() {
        return <Dialog
            open={this.state.isOpened}
            onClose={() => this.closeDialog(false)}
        >
            <DialogContent>
                <DialogContentText style={{marginBottom: 10}}>
                    Ajouter ci-dessous le code HTML contenant l'iframe de la vidéo
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Iframe vidéo"
                    fullWidth
                    multiline
                    id="iframeVideo"
                    value={this.state.iframeVideo}
                    onChange={this.handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.closeDialog(false)}>Fermer</Button>
                <Button onClick={() => this.closeDialog(true)} autoFocus>Enregistrer</Button>
            </DialogActions>
        </Dialog>
    }
}

export default UploaderPopupMedia;